import React from "react";

/**
 * React class to show the painting paths for the svg
 */
const PaintingPaths = (props) => {
  return (
    <>
      <path
        className="cls-2"
        d="M1497.71,1393.94c-165.1-158.49-342.06-214.09-385.42-239.29-91-400.24-412.94-499.77-412.94-499.77-88.94,65.65-559.06,247.77-559.06,247.77s75.64,37,161.8,98.76l-3-1.35c-87.89,30.21-148.16,77.33-179.68,106.74l-2.44-.86c-61.41,27.53-112.24,46.59-57.18,61.41s476.47,232.94,501.89,283.77,118.9,86.52,283.76,264.7c209.65,226.59,201.18,285.89,247.77,243.53,73.66-67,397.55-277,465.88-298.59,40.23-12.7,12.7-44.47,12.7-44.47L1568,1615c7.06-3.59,11.46-7.57,10.58-11.93-1.22-6-9.92-24.86-27.34-34.1,16.73-4.92,34.24-9.65,41.73-9.82S1603.59,1495.59,1497.71,1393.94Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-1"
        d="M1497.71,1393.94c-165.1-158.49-342.06-214.09-385.42-239.29-91-400.24-412.94-499.77-412.94-499.77-88.94,65.65-559.06,247.77-559.06,247.77s75.64,37,161.8,98.76l-3-1.35c-135.53,46.59-205.41,133.41-205.41,133.41s328.23,74.12,465.88,296.47c0,0,4.23,23.3,31.76,40.24s228.71,95.29,472.24,385.41c140.82-83.21,288-166.33,442.29-221.59,14-5,75.82-15.67,72.73-31-1.22-6-9.92-24.86-27.34-34.1,16.73-4.92,34.24-9.65,41.73-9.82S1603.59,1495.59,1497.71,1393.94Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M565.94,1355.82l-12.7,12.71c-4.83-13.92,4.23-4.24,0,0C533,1307,492,1254,442.23,1213.11c0,0,32.65-37.29,45.36-33"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M1112.29,1154.65c-91-400.24-412.94-499.77-412.94-499.77-88.94,65.65-559.06,247.77-559.06,247.77s190.59,93.17,311.3,230.82,124.94,309.18,124.94,309.18-38.12,6.35,63.53,44.47,307.06,133.41,451.06,298.59c131.9-80,275.67-166.17,425.46-206.42,13.41-3.6,62.43-19.85,76.42-20.17,7.42-.17,10.59-63.53-95.29-165.18C1332.61,1235.45,1155.65,1179.85,1112.29,1154.65Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M299.12,1000.06c-135.53,46.59-205.41,133.41-205.41,133.41s328.23,74.12,465.88,296.47c0,0,4.23,23.3,31.76,40.24s228.71,95.29,472.24,385.41c140.82-83.21,288-166.33,442.29-221.59,14-5,75.82-15.66,72.73-31-1.28-6.34-10.83-26.84-30.08-35.45"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M299.12,1000.06c-135.53,46.59-205.41,133.41-205.41,133.41s328.23,74.12,465.88,296.47c0,0,4.23,23.3,31.76,40.24s228.71,95.29,472.24,385.41c140.82-83.21,288-166.33,442.29-221.59,14-5,75.82-15.66,72.73-31-1.28-6.34-10.83-26.84-30.08-35.45"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M117,1105.94c-61.41,27.53-112.24,46.59-57.18,61.41s476.47,232.94,501.89,283.77,118.9,86.52,283.76,264.7c209.65,226.59,201.18,285.89,247.77,243.53,73.66-67,397.55-277,465.88-298.59,40.23-12.7,12.7-44.47,12.7-44.47"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M768.61,838.4c21.79-13.64,44.37-26.12,68.25-35.73,5.77-2.32,11.72-4.5,17.79-6.41C808.81,746,760.31,706.18,714.18,693c0,0-47.15,24.38-111.39,56.68,14.81,8.29,29.87,16.15,44.4,24.91,25,15.11,48.42,33.63,70.57,52.67q14.54,12.48,28.34,25.79C753.53,848.06,761,843.15,768.61,838.4Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M992.69,983.86c-34.62-55.4-78.21-119.09-125.17-173.14-15.76,4-31.06,10.68-45.17,17.61-22.05,10.84-43,23.72-63.46,37.33a722.24,722.24,0,0,1,76.14,91,647.91,647.91,0,0,1,49.25,83C919.85,1021.33,954.51,995.46,992.69,983.86Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M1002.24,999.31c-14.76,4.1-29,10.72-42.23,17.68-23,12.14-44.8,26.72-68,38.58q7.5,15.77,14.38,31.77a769.35,769.35,0,0,1,33.54,93c66.07-32.71,118.92-58.19,130-61.65C1069.94,1118.65,1043.63,1066.9,1002.24,999.31Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M651.64,960.31c20.37-17.75,41.78-34.78,64.63-49.22,9.56-6,19.64-11.89,30.44-15.39a43.27,43.27,0,0,1,6.25-1.56,683.86,683.86,0,0,0-71-65.24,492.39,492.39,0,0,0-69.3-46.19c-12-6.61-24-13.13-35.64-20.13-30.23,15.11-63.05,31.36-95.85,47.31,11.3,10.31,22.41,20.87,33.55,31.35,23.12,21.75,45,44.75,66,68.61q28,31.95,54.08,65.78C640.18,970.27,646,965.27,651.64,960.31Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M875.69,1077.58c-9.61-20.68-20-41-31.33-60.72-1,.36-2.08.75-3.11,1.14l-.22.08-.39.18-1.57.72c-1.34.62-2.66,1.27-4,1.94-23.79,12.14-45.6,28.66-66.84,44.73-15.39,11.64-31.24,23.9-46.17,36.87,13.42,22.43,26,45.39,37.44,68.88,12.93,26.47,24.86,53.89,34.05,82.1,41.12-20.72,84.43-42.44,124.32-62.27A729.1,729.1,0,0,0,875.69,1077.58Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M730.43,1073c23.6-18.9,47.7-37.41,73.09-53.86,10.15-6.58,20.58-12.94,31.79-17.55A609,609,0,0,0,801,950.93c-10.07-13.32-36-43-42.24-39.68a6.61,6.61,0,0,0-.92,0c-.74.07-1.63.19-1.51.15-1.68.35-9.32,2.91-9.58,3l-.82.36c-21.56,10.06-41.16,24.8-59.63,39.5-13.93,11.09-28.39,22.5-40.74,35.5q25.35,33.72,48.69,68.78,9.5,14.25,18.53,28.78C718.61,1082.44,724.52,1077.68,730.43,1073Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M471.7,956.88c29.65-12.32,60.07-23.21,91.35-30.6q-6.62-7.51-13.34-14.93c-20.93-23-42.86-45.06-66-65.84-8.75-7.87-17.25-16.32-26.19-24.15-90.94,43.84-176.78,82.7-200.79,85.52-17.69,2.08,45.94,27.18,126.05,91.89C411.85,983.59,441.44,969.47,471.7,956.88Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M506.79,1127c51.54-24.83,105.48-44.67,155.56-72.47-27.06-39-55.58-77-86.27-113.18a486.71,486.71,0,0,0-53.77,15.07c-43,14.58-84.57,33.35-125,54.35A701.48,701.48,0,0,1,506.79,1127Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M729.08,1164.61a997.18,997.18,0,0,0-56.75-95.44c-15.44,8.62-31.27,16.49-47.3,24-35.6,16.58-72.33,31.31-107.83,48.35a649.23,649.23,0,0,1,95.33,203.76s72.07-36.76,159.12-80.69C760.79,1229.86,746,1197.05,729.08,1164.61Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M333,1025.47c-12.18,4.75-24.22,9.89-36.16,15.22-21.82,9.73-43.68,20.08-63.77,33.08-8.22,5.32-17.22,11.3-22.89,19.47,3.91,1,7.79,2.12,11.66,3.28C292,1118,359,1150,417.81,1193.73c0,0,40.13-41.2,57.07-32.73"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M597.71,1436.29c105.88-55,504-268.94,504-268.94"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M1316.61,1446.55c28.1-17.39,56-35.24,85.93-49.39,5.68-2.69,11.42-5.3,17.16-7.89-69.5-58.35-155.14-110.08-216.55-144.06-41.08,27.37-87.18,47.69-131.3,69.38,3.22,2,6.43,3.91,9.62,5.91,45.29,28.34,87.76,61.08,127.89,96.3,20.93,18.36,41.06,37.59,61.25,56.75C1286.27,1465.11,1301.49,1455.91,1316.61,1446.55Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M1433.78,1401.38c-9.37,4.23-18.75,8.43-28,12.93-14.8,7.21-29.13,15.36-43.25,23.83-26.37,15.82-52.06,32.79-79,47.64q12.07,11.38,24.33,22.59c25.22,23,50.29,46.37,72.62,72.19,81.94-34.37,149-59.56,149-59.56C1512.9,1480.69,1477.32,1439.8,1433.78,1401.38Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M1055.08,1304.05c29.65-14.81,59.7-28.84,89-44.35,14.17-7.5,28.35-15.28,42-23.81-38-20.55-63.24-32.54-63.24-32.54s-58,27.59-137.09,66.09q35.18,15.78,68.7,34.94C1054.68,1304.27,1054.87,1304.15,1055.08,1304.05Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-10"
        d="M1119,1413.24c8.32-5.13,16.9-9.83,25.37-14.71l6.18-3.6c-58.22-45.74-121.44-84.54-189.49-113.45-26.48,12.93-54.72,26.79-83.54,41,21.61,8.77,42.12,20.61,60.82,33.79,41.54,29.28,77.56,65.75,109.54,105.15C1069.25,1442,1094.49,1428.33,1119,1413.24Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M1279.91,1511.16c-34-31-66.26-63.7-101.41-93.45q-6.87-5.82-13.84-11.5c-12.73,8.26-26.58,14.77-39.31,23-11,7.16-22.86,13.11-33.92,20.29a239.38,239.38,0,0,0-33,25.23c.65.84,1.32,1.67,2,2.52,28.44,36.88,53,76.72,75.85,117.29,14.66,26,28.63,53,40.31,80.78,52.83-28.71,121.22-59.66,183.71-86.25C1335.38,1561.27,1307.49,1536.27,1279.91,1511.16Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M1059.11,1510.56c-8.17-11.53-16.66-22.84-25.57-33.83-34.37-42.42-73.67-82.49-120-111.91-19.44-12.35-40.47-22.72-62.32-29.25-71.5,35.56-144.3,72.52-200.61,102.84,119.73,47.61,210.22,95.95,277.51,139C968.62,1549.62,1015.1,1532,1059.11,1510.56Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M1108.46,1588.12c-12.62-21.55-25.68-42.86-39.65-63.53-12,5.86-24.16,11.42-36.31,17-29.74,13.59-61.2,27.41-89.18,45.7,118.49,78.45,158.39,137,158.39,137,12.63-11.6,32.31-24.75,56.43-38.64C1144.22,1651.91,1126.85,1619.52,1108.46,1588.12Z"
        transform="translate(-35.29 -164.02)"
      />
      <path
        className="cls-13"
        d="M919.59,1639.59c50.82,57.17,114.35,135.53,114.35,135.53l29.65-14.83"
        transform="translate(-35.29 -164.02)"
      />
    </>
  );
};

export default PaintingPaths;
